.metrics-container {
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin-top: 2vh;
}

.metric-card {
  background: var(--bg-color);
  border-radius: 8px;
  padding: 15px 30px;
  display: flex;
  align-items: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
}

.clickable-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 2vh;
  flex-wrap: wrap;
  gap: 10px;
}

.clickable-card {
  background: var(--bg-color);
  border-radius: 8px;
  padding: 15px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
  flex: 1 1 calc(33.333% - 20px);
  width: 250px;
  height: 108px;
  /* overflow: auto; */
}
.clickable-simple-card {
  background: var(--bg-color);
  border-radius: 8px;
  padding: 15px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
  width: 200px;
  height: 108px;
}
.active-card {
  border: 1px solid #000;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}
.metric-card:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.metrixicon {
  margin-right: 25px;
}

.metrixdetails {
  text-align: right;
}

.metrixdetails h4 {
  margin: 0;
  font-size: 18px;
  color: #666;
}

.metrixdetails h2 {
  margin: 10px 0 0;
  font-size: 24px;
  color: #333;
}
