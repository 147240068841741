/* .header {
  background-color: var(--bg-color);
  color: var(--text-color);
} */
.nav-list li {
  padding: 0px 10px;
  border-radius: 15px;
}

.headerContainer {
  background-color: var(--bg-content);
  color: var(--text-color);
  font-weight: 600;
  font-size: 14px;
}

.userLoginDetails {
  margin: 5px 20px;
  padding: 5px 10px;
}

.d-flex .nav-list {
  padding: 0;
  border-radius: 15px;
  margin: 0;
}

.d-flex.nav-list li {
  cursor: pointer;
}

/*.d-flex.nav-list li:hover {
  background-color: #f5f5f5;
  color: #262626 !important;
  transition: background-color 0.4s ease-in-out;
  transform: scale(1.07);
}*/

.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.dropdown-basic button {
  border: none !important;
  color: var(--text-color);
  font-size: 14px;
  background: unset;
}

/*.sticky-element {
  position: sticky;
  top: 0;
  z-index: 100;
}*/

.navLink {
  margin-right: "auto";
}

a {
  color: var(--text-color);
}