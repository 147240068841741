.ProgressBar .bg-body-tertiary {
  width: 100%;
  display: flex;
  align-items: center;
  background-color: #f5f5f5 !important;
}

.link {
  position: relative;
  text-decoration: none;
}

.link::after {
  content: "";
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 0%;
  height: 2px;
  background-color: #f58e0e;
  transition: width 0.3s ease;
}

.link:hover::after {
  width: 100%;
}

.link:hover {
  transform: scale(1.05);
}

.rounded {
  cursor: pointer;
}

.navbar-toggler-icon {
  color: var(--text-color) !important;
}

.navbar-toggler:focus {
  text-decoration: none;
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
}

button:focus:not(:focus-visible) {
  outline: none;
  border: none;
}

.navbar-toggler-icon {
  background-image: url(../../../images/menu-svgrepo-com.svg) !important;
  width: 2em !important;
}

.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  /* Black background with opacity */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
}

/* progressbar.css */
.ProgressBar {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 250px;
  background-color: var(--bg-color);
  transition: width 0.3s ease;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ProgressBar.collapsed {
  width: 80px;
}

.toggle-button {
  position: relative;
  top: 0px;
  background-color: var(--bg-color) !important;
  color: var(--text-color) !important;
  border: none;
  padding: 10px;
  cursor: pointer;
  z-index: 1000;
  border-radius: 4px;
  /* Add some border radius for better appearance */
}

.toggle-button {
  color: var(--text-color) !important;
}
.toggle-button:hover {
  background-color: var(--hover-bg) !important;
  color: var(--hover-color) !important;
}

.link {
  display: flex;
  align-items: center;
  padding: 15px 20px;
  color: var(--text-color) !important;
  font-size: 14px;
  /* Increase font size */
  transition: background-color 0.3s;
  width: 100%;
  /* Ensure links take the full width */
}

.active-link {
  background-color: var(--text-color) !important;
  color: var(--bg-color) !important;
}

.link:hover {
  background-color: var(--hover-color) !important;
  color: var(--hover-bg) !important;
}

.link .me-2 {
  min-width: 30px;
  /* Ensure consistent spacing */
  margin-right: 5px !important;
  font-size: 1.2rem;
  /* Increase icon size */
}

.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}
