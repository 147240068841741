.Common_container {
  padding: 20px 0 0 0;
}

/* search bar css  */

.group {
  display: flex;
  line-height: 28px;
  align-items: center;
  position: relative;
  max-width: 290px;
}

.searchinput {
  width: 100% !important;
  height: 40px;
  line-height: 28px;
  padding: 0 1rem !important;
  padding-left: 2.6rem !important;
  border: 2px solid transparent;
  border-radius: 8px !important;
  outline: none;
  background-color: #f3f3f4;
  color: #0d0c22;
  transition: 0.3s ease;
  margin: 0px 20px !important;
}

.searchinput::placeholder {
  color: #9e9ea7;
}

.searchinput:focus,
input:hover {
  outline: none;
  border-color: rgba(0, 48, 73, 0.4);
  background-color: #fff;
  box-shadow: 0 0 0 4px rgb(0 48 73 / 10%);
}

.icon {
  position: absolute !important;
  left: 2rem !important;
  fill: #9e9ea7 !important;
  width: 1rem !important;
  height: 1rem !important;
}

/* pagination style */

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px 0;
  font-size: 12px;
}

.pagination button {
  border: none;
  /* background-color: var(--bg-color) !important;
  color: var(--text-color) !important; */
  padding: 10px 15px;
  margin: 0 5px;
  border-radius: 30px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
}

.pagination button:hover {
  background-color: var(--bg-color);
  color: var(--text-color);
  transform: scale(1.1);
}

.pagination button.active {
  font-weight: bold;
  background-color: var(--bg-color);
  color: var(--text-color);
}

.pagination button:disabled {
  background-color: var(--bg-color);
  cursor: not-allowed;
}

.toggleAdd {
  color: var(--text-color) !important;
}
