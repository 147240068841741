@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");

:root {
  --bg-color: #efefef !important;
  --text-color: #000000 !important;
  --bg-content: #dddbe0 !important;
  --bg-chart: var(--bg-color) !important;
  --bg-table: #7c7386 !important;
  --hover-color: #000000 !important;
  --hover-bg: #ffffff !important;
}

[data-theme="dark"] {
  --bg-color: #1e171f !important;
  --text-color: #ffffff !important;
  --bg-content: #7c7386 !important;
  --bg-chart: #83767d !important;
  --hover-color: #ffffff !important;
  --hover-bg: "#000000" !important;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Lato", sans-serif;
  font-weight: 300;
  font-style: normal;
  background-color: var(--bg-color);
  color: var(--text-color);
}

code {
  font-family: "Lato", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.modal-dialog .modal-content {
  background-color: var(--bg-color) !important;
  color: var(--text-color) !important;
}

.popover-arrow-container div:nth-child(2) {
  background-color: var(--bg-color) !important;
  color: var(--text-color) !important;
}